import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n.js';

import './AdminClasses.css';

function AdminClasses({ usersList, classesList }) {

  const { t } = useTranslation();
  const [classSelected, setClassSelected] = useState(null);

  const getUserNameById = (userId) => {
    let theUser = usersList.filter((u) => u._id === userId);
    return theUser.length > 0 ? (theUser[0].name + ' ' + theUser[0].surname) : '';
  }

  const setTheClassSelected = (theClass) => {
    setClassSelected(theClass);
  }

  return (
    <div className='divAdminUsers'>
        <div className='divClasses'>
          <table>
            <thead>
              <tr>
                <th>{t('class_id')}</th>
                <th>{t('class_name')}</th>
                <th>{t('class_school_name')}</th>
                <th>{t('class_user')}</th>
                <th>{t('class_students')}</th>
              </tr>
            </thead>
            <tbody>
              {classesList.map((tClass) => (
                <tr onClick={() => setTheClassSelected(tClass)} key={tClass._id} className={`${classSelected && tClass._id === classSelected._id && 'trSelected'}`}>
                  <td>{tClass._id}</td>
                  <td>{tClass.name}</td>
                  <td>{tClass.school_name}</td>
                  <td>{getUserNameById(tClass.user_id)}</td>
                  <td>{tClass.students.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </div>

  );
};

export default AdminClasses;
