import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import CatchFruits from './CatchFruits.js';
import './Games.css';
import MemoryFruits from './MemoryFruits.js';

function Games({ user, message, setMessage, loading, setLoading, logoutMain }) {
  const { t } = useTranslation();

  const memoryFruits = 'memoryfruits';
  const catchFruits = 'catchfruits';
  const [gameSelected, setGameSelected] = useState(null);

  const setGame = (game) => {
    setGameSelected(game);
  }

  return (
    <div className='games'>
      <div className='head'>
        <button onClick={() => setGame(memoryFruits)} className={ gameSelected === memoryFruits ? 'gameSelected': ''}>{t('memoryfruits')}</button>
        {/*<button onClick={() => setGame(catchFruits)} className={ gameSelected === catchFruits ? 'gameSelected' : ''}>{t('catchfruits')}</button>*/}
      </div>
      {gameSelected === memoryFruits &&
        <MemoryFruits />
      }

      {gameSelected === catchFruits &&
       <CatchFruits />
       }
    </div>
  );
};

export default Games;
