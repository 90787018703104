import React, { useEffect, useState } from 'react';
import './Podium.css';
import '../language/i18n.js';
import { useTranslation } from 'react-i18next';

//Chart is necessary to Bar works
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

function Podium({ title, theList }) {
  const { t } = useTranslation();

  const [options, setOptions] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    setOptions({
      scales: {
        x: { // Update to "x"
          stacked: true,
        },
        y: { // Update to "y"
          stacked: true,
        },
      },
    });

    if (theList && theList.length > 0) {
      setData({
        labels: theList.map((st) => st.name),
        datasets: [
          {
            label: t('podium-total-points'),
            data: theList.map((st) => st.total),
            backgroundColor: theList.map((st) => st.color ? st.color : 'grey')
          },
        ],
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theList]);


  return (
    <div className='divPodium'>
      <h1>{title}</h1>
      <div className='divPodiumTop3'>
        {theList && theList.length >= 1 &&
          <div>
            <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{theList[0].name}</h3>
          </div>
        }
        {theList && theList.length >= 2 &&
          <div>
          <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{theList[1].name}</h3>
          </div>
        }
        {theList && theList.length >= 3 &&
          <div>
          <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{theList[2].name}</h3>
          </div>
        }
      </div>
      <div className='divPodiumBar'>
        {data && data.datasets && <Bar data={data} options={options} />}
        {(!data || !data.datasets) && <p>{t('no-stats-yet')}</p>}
      </div>
    </div>
  );
};

export default Podium;
