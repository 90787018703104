import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n.js';

import './AdminUsers.css';
import moment from 'moment';

function AdminUsers({ usersList }) {

  const { t } = useTranslation();

  const [userSelected, setUserSelected] = useState(null);

  const setTheUserSelected = (theUser) => {
    setUserSelected(theUser);
  }

  

  return (
    <div className='divAdminUsers'>
        <div className='divUsers'>
          <table>
            <thead>
              <tr>
                <th>{t('user_id')}</th>
                <th>{t('user_name')}</th>
                <th>{t('user_surname')}</th>
                <th>{t('user_email')}</th>
                <th>{t('user_language')}</th>
                <th>{t('user_enabled')}</th>
                <th>{t('user_creationDate')}</th>
                <th>{t('user_accesses')}</th>
                <th>{t('user_last_access')}</th>
                <th>{t('user_isadmin')}</th>
              </tr>
            </thead>
            <tbody>
              {usersList.map((tUser) => (
                <tr onClick={() => setTheUserSelected(tUser)} key={tUser._id} className={`${userSelected && tUser._id === userSelected._id && 'trSelected'}`}>
                  <td>{tUser._id}</td>
                  <td>{tUser.name}</td>
                  <td>{tUser.surname}</td>
                  <td>{tUser.email}</td>
                  <td>{tUser.language}</td>
                  <td>{tUser.enabled ? 'SI' : 'NO'}</td>
                  <td>{moment(tUser.creationDate).format('DD/MM/YYYY')}</td>
                  <td>{tUser.accesses.length}</td>
                  <td>{tUser.accesses.length > 0 ? moment(tUser.accesses[tUser.accesses.length - 1]).format('DD/MM/YYYY') : ''}</td>
                  <td>{tUser.isAdmin ? 'SI' : 'NO'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
    </div>

  );
};

export default AdminUsers;
